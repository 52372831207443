@import "variables.css";
@import "font.css";
@import "components/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    padding: 0 10vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: $grey;
    font-family: "InterTight-Light";
}

p {
    margin: 20px 0;
}